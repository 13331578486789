.slideshow-wrap {
    position: relative;
    //@extend .bg-color-black;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }

    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 99;
        .title-box {
          background: rgba(0, 0, 0, 0.4);
          margin: auto;
          display: inline-block;
          padding: 15px;
          .content {
            border: 1px solid $color-white;
            padding: 30px 50px;
            .title {
              line-height: 1.5;
              text-shadow: 1px 1px 10px $color-black,
                          -1px -1px 10px $color-black;
                          font-size: $font-size-30;
            }
          }
        }
        img {
          margin: auto;
          width: 10%;
          max-width: 240px;
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
      .caption {
        img {
          width: 120px;
        }
      }
    }
}
