// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .row {
      margin-bottom: 0;
    }

    //Parallax
    .parallax-container {
      position: relative;
      overflow: hidden;
      height: 500px;
    }

    .parallax-container .parallax {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .parallax-container .parallax img {
      opacity: 0;
      position: absolute;
      left: 50%;
      max-width: initial!important;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }

}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

@import 'sections/section01-index';

@import 'sections/section02-index';

@import 'sections/section03';

@import 'sections/parallax-bg';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
@import 'modules/module-header';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/
.section-realisations {
  padding: 70px 0;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    a {
      transition: .3s;
      &:hover {
          box-shadow: 0 0 10px $color-black;
          transition: .3s;
      }
    }
  }
}
.product-popup {
    .product-wrapper {
        @extend .flex;
        // @extend .flex-wrap;
        @extend .justify-start;
        @extend .items-center;
        .product-image {
            flex: 1;
        }
        .product-info {
            flex: 1;
            padding: 10px;
        }
    }
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 90%;
    margin: 20px auto;
    img.mfp-img {
        padding: 0;
    }
    .sku, .title, .categories {
        padding: 20px 0;
        font-size: 20px;
        font-weight: 700;
    }
    .title {
        text-transform: uppercase;
    }
    .sku {
        text-align: right;
    }
    div.info {
        @extend .flex;
        @extend .items-center;
        @extend .justify-between;
    }
    .more-info {
        @extend .flex;
        @extend .justify-between;
        @extend .items-center;
        padding: 10px 0;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
        .pdf {
            height: 35px;
            a.pdf-link {
                margin-right: 20px;
            }
        }

        .readmore {
            a.plus {
                //@extend .font-1-light;
                font-size: 40px;
                color: $color-white;
            }
        }
    }
    div.description {
        padding: 10px 0;
        //@extend .font-1;
        color: $color-grey-light;
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
    }
    @media screen and (max-width: $size-xs-max) {
        .sku, .title, .categories, div.description {
            font-size: $font-size-16;
            text-align: left;
            padding: 0;
        }
        .product-wrapper {
            flex-direction: column;
            .product-info .info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

/******************************************************************************/
////PAGE SOUMISSION
.section-form {
  padding: 70px 0;
  .services {padding-top: 50px;}
  .services-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: $color-black;
    }
    & > label {
        margin: 20px;
    }

  }
  .btn-submit {
    background: $color-grey-light;
    padding: 5px;
  }
  @media screen and (max-width: $size-xs-max){
    .services-box {
      flex-direction: column;
      & > label {
          margin: 10px;
      }
    }
    .g-recaptcha > div {
      margin: auto;
    }
  }
  @media screen and (max-width: 350px){
    .g-recaptcha {
      transform:scale(0.77);
      transform-origin:0 0;
    }
  }

}



////////////////////////////////////////////////////////////////////////////////
////// page coordonnees
.section-coordonnees {
  background-color: $color-grey-light;
  padding-top: $size-nav-height;
  transition: .3s;
  @media screen and (max-width: $size-nav-shift-breakpoint){
    padding-top: $size-nav-height-mobile;
    transition: .3s;
  }

  .container-fluid.row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    & > div {
      width: 50%;
    }

    .firstHeading {
      color: $color-black;
    }
    .map-link:hover{color: $color-black;}

    .left-side {
      background-image: radial-gradient(circle at center right, #a20707 3%, #6b0505 );
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: right;
      padding: 40px 10px;

      h3 {
        font-size: $font-size-34;
        text-transform: uppercase;
        padding-bottom: 30px;
      }

      .address {
        border-top: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        padding: 30px 0;
      }


      p {
        font-size: $font-size-34;
        font-weight: 400;
        color: $color-white;

        &.phone {
          font-size: $font-size-24;
          padding-top: 30px;
          a {
            font-size: $font-size-40;
            color: $color-white;
            &:hover {
              color: $color-black;
            }
          }
        }
      }

      .email {
        font-size: $font-size-22;
        padding-top: 30px;
        color: $color-white;
        &:hover {
          color: $color-black;
        }
      }

    }
    @media screen and (max-width: $size-xs-max){
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
}
