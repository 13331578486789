.module-header {
    position: relative;

    .caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        .text-box {
          min-width: 30%;
          background-color: rgba(0,0,0,0.4);
          padding: 10px;
          .border {
            border: 1px solid $color-white;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            h3 {
                width: 100%;
                color: #fff;
                padding: 30px;
                font-weight: 700;
                text-align: center;
                font-size: $font-size-34;
                text-transform: uppercase;
                text-shadow: 0 0 10px #000;
            }
          }
        }
    }
}
