.parallax {
  position: relative;
  overflow: hidden;
  background-color: #000;
  .image-bg {
    background-attachment: fixed;
    background-size: cover;
    background-image: url('../images/section_02_bg_parallax.jpg');
    //background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    //z-index: 500;
    //opacity: 0.4;
  }
}
