section.section02-index {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  div.container.row {
    z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      .btn-box {
        background: $color-black;
        padding: 10px;
        margin-top: 10px;
        .border {
          border: 2px solid $color-white;
          padding: 15px 70px;
          text-align: center;
          display: block;
          h4 {
            color: $color-white;
            font-size: $font-size-24;
          }
          @media screen and (max-width: 400px){
            padding: 15px 30px;
          }
        }
      }
    }
}
