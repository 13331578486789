section.section01-index {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px;

    div.container-fluid.row {
        display: flex;
        align-items: stretch;

        width: 100%;
        padding: 0;
        div.left-side.burst-left,
        div.right-side.burst-right {
            width: 50%;
            padding: 100px 0;
        }

        div.left-side.burst-left {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            transition: .5s;

            & > div {
              margin: 30px 0;
            }

            div.box-title-bg {
                width: 90%;
                padding: 15px;
                height: 180px;
                padding-right: 6vw;
                background: $color-primary;
                justify-content: flex-end;
                align-items: center;
                display: flex;


                h3 {
                    color: #FFF;
                    font-weight: 400;
                    font-size: $font-size-30;
                    line-height: 1.2;
                    transition: .5s;
                    text-align: center;
                    span {
                      font-size: $font-size-50;
                    }
                }
            }

            div.image-box {
              width: 90%;
              img {margin: auto;}
            }
        }

        div.right-side.burst-right {

            div.content-paragraph {
                width: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5vw;
                flex-direction: column;
                p {
                    font-size: $font-size-20;
                    padding-bottom: 25px;

                }
            }
            div.images-box {
              width: 80%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-left: 3vw;
              padding-top: 40px;

            }

        }

        //Media query du Style en Mobile
        @media screen and (max-width: $size-xs-max) {
          flex-direction: column;


          div.left-side.burst-left,
          div.right-side.burst-right {
              width: 100%;
              padding: 40px 0;


              div.box-title-bg {
                width: 100%;
                justify-content: center;
              }


              div.content-paragraph {
                width: 90%;
                padding: 30px 0;
                margin: auto;
                p {
                  text-align: center;
                }
              }
              div.images-box {
                flex-direction: column;
                width: 100%;
                img {
                  margin: 30px auto;
                }
              }
          }

        }


    }
}
