.section03 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;

  div.container-fluid.row {
      display: flex;
      align-items: stretch;

      width: 100%;
      padding: 0;
      div.left-side.burst-left,
      div.right-side.burst-right {
          width: 50%;
      }
      div.left-side.burst-left {

        .burst-01{background-image: url('../images/burst_urgence_bg.jpg');}
        .burst-02{background-image: url('../images/burst_soumission_bg.jpg');}
        .burst-03{background-image: url('../images/burst_realisations_bg.jpg');}

        .burst-03,
        .burst-01,
        .burst-02 {
          height: 50%;
          padding: 50px 0;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          a {
            .content-img {
              height: 180px;
              width: 180px;
              margin: auto;
              background: $color-primary;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 4px solid $color-white;
              transition: all .2s ease;
            }
            .title-burst {
              font-weight: 400;
              color: $color-black;
              text-align: center;

              span {
                color: $color-primary;
                text-transform: uppercase;
                font-weight: 700;
                font-size: $font-size-30;
                text-shadow: 0 0 10px $color-white;
              }
            }
            &:hover {
              .content-img {
                background: $color-black;
                border: 4px solid $color-black;
                transition: all .2s ease;
              }

            }
          }
        }

      }



      div.right-side.burst-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 70px;
        div.box-title-bg {
            width: 90%;
            padding: 15px;
            height: 180px;
            //padding-right: 6vw;
            background: $color-primary;
            justify-content: flex-start;;
            align-items: center;
            display: flex;


            h3 {
                color: #FFF;
                font-weight: 500;
                font-size: $font-size-30;
                line-height: 1.2;
                text-transform: uppercase;
                transition: .5s;
                span {
                  color: $color-black;
                  font-weight: 700;
                }
            }
        }
        .text-box {
          width: 90%;
          padding: 30px 0;
        }
      }

      @media screen and (max-width: $size-xs-max){
        flex-direction: column;
        div.left-side.burst-left,
        div.right-side.burst-right {
            width: 100%;
            div.box-title-bg {
                width: 100%;
                justify-content: center;
                h3 {text-align: center;}
            }
            .text-box {
              margin: auto;
              ul {
                max-width: 350px;
                margin: auto;
              }
            }
        }
      }

    }
}
